<template>
      <div class="companyEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'company', params: {} }">
          Company
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'companyEdit', params:{id: id} }">
            Edit Company
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Company</v-card-title>
          <v-card-text>
            <company-form :id="id"></company-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import CompanyForm from './CompanyForm.vue';

    export default {
      name: 'company',
      data () {
        return {
          id: null
        }
      },
      components: {
        CompanyForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    